body {
  margin: 0;
  padding: 0;
  line-height: 100%;
  font-family: arial, helvetica, tahoma, verdana, sans-serif;
  color: #00183a;
}

header,
footer {
  width: 100%;
  background-color: rgb(242, 246, 255);
}

header {
  position: fixed;
}

footer {
  font-size: 80%;
}

header div,
main,
footer div {
  max-width: 75rem;
  margin: 0 auto;
}

header div,
footer div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  width: 100%;
}

header div {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

header div > a,
footer div > p {
  padding-left: 1rem;
}

header nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

header nav li {
  display: inline-block;
  border-bottom: 0.25rem solid transparent;
}

header nav li.active {
  border-bottom: 0.25rem solid #007bff;
}

header nav li a,
main article h2 {
  font-weight: 500;
  letter-spacing: 0.04rem;
}

header nav li a {
  display: block;
  padding: 0.5rem 0.5rem 0.25rem 1rem;
  text-decoration: none;
  color: #00183a;
  font-size: 120%;
}

header nav li a:hover,
header nav li.active a {
  color: #3668ad;
}

main article h1,
main article h2 {
  margin-top: 0;
  text-align: center;
  width: 100%;
}

main article h1 {
  font-weight: 500;
  font-size: 1.2rem;
}

main article h2 {
  font-size: 150%;
  padding-bottom: 2rem;
}

main {
  line-height: 150%;
}

main a,
footer a {
  color: #3668ad;
}

main a[target="_blank"]:not([href*="rtz.ch"]):not(address a):after {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    margin-left: 0.2rem;
    content: "";
    background: transparent url(external-link.svg) 0 0 no-repeat;
    background-size: 1rem;
}

article {
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  padding-top: 8rem;
}

article:first-child {
  padding-top: 12rem;
}

article:last-child {
  padding-bottom: 24rem;
}

section {
  flex: 2;
  order: 2;
}

aside {
  flex: 1;
  order: 1;
}

section,
aside {
  padding: 0 1.25rem 0 1.25rem;
}

article:nth-child(2n) section {
  order: 1;
  padding: 0 1.25rem 0 1.25rem;
}

article:nth-child(2n) aside {
  order: 2;
  padding: 0 1.25rem 0 1.25rem;
}

aside img {
  width: 100%;
  padding-top: 1rem;
}

#contactdata > div {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

#contactdata > div > div:first-child  {
  flex: 1;
}

#contactdata > div > div:last-child  {
  flex: 2;
}

#contactdata ul li:not(:first-child) {
  padding-top: 1rem;
}

article > address {
  flex: 1;
  flex-basis: 100%;
  font-style: normal;
  padding: 2rem;
}

article > address ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-block: 0;
  padding: 0;
  width: 50%;
  margin: auto;
}

article > address li {
  text-decoration: none;
}

article > address svg {
  width: 2rem;
  height: 2rem;
}

article > address svg:hover {
  fill: #3668ad;
}

footer p {
  flex: 3;
  padding-right: 1rem;
}

footer address {
  flex: 2;
}

@media screen and (max-width: 800px) {
  article:nth-child(2n) aside {
    order: 2;
  }

  section {
    flex: 1 100%;
    order: 1;
  }

  aside {
    flex: 1 100%;
    order: 2;
    padding-top: 0;
  }
}

@media screen and (max-width: 700px) {
  header div {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  header img {
    display: none;
  }

  section,
  aside,
  article:nth-child(2n) section,
  article:nth-child(2n) aside {
    padding: 0 0.25rem 0 0.25rem;
  }

  header nav li a {
    padding: 0.25rem 0.25rem 0.25rem 0.25rem;
    font-size: 100%;
  }

  main article:first-child {
    padding-top: 6rem;
  }

  footer {
    font-size: 70%;
  }
}
